import {DisposableInterface} from "framework/interfaces";

function tryDispose(disposable: DisposableInterface) {
  disposable.dispose();
}

class Bucket {
  private disposables: Array<DisposableInterface> = [];

  constructor() {
    this.disposables = new Array<DisposableInterface>();
  }

  public add(disposable: DisposableInterface) {
    this.disposables.push(disposable);
  }

  public dispose() {
    this.disposables.map(tryDispose);
    this.disposables = [];
  }
}

export default Bucket;
