import * as THREE from 'three';
import {Renderer, WebGLRenderer} from 'three';
import {WEBGL} from "system/WebGL";
import "regenerator-runtime";
import {LevelInterface} from "level/level";
import Intro from "level/intro";

let currentLevel: LevelInterface;
const canvas = document.querySelector('#c');
if (!canvas) {
  throw "Canvas not found";
}

const renderer = new WebGLRenderer({
  // powerPreference: "high-performance",
  antialias: false,
  alpha: true,
  stencil: true,
  depth: true,
  logarithmicDepthBuffer: true,
  canvas
});

renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.outputEncoding = THREE.sRGBEncoding;
// renderer.gammaFactor = 2.2;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 0.6;
renderer.physicallyCorrectLights = true;
renderer.shadowMap.enabled = true;
renderer.shadowMap.autoUpdate = true;
renderer.shadowMap.type = THREE.VSMShadowMap;

document.body.appendChild(renderer.domElement);

const clock = new THREE.Clock(true);
clock.start();
const FPS = 60;
const FRAME_DELTA = 1 / FPS;
let accum = clock.getDelta();
// Fix the timestep https://gafferongames.com/post/fix_your_timestep/
function animate() {
  if (resizeRendererToDisplaySize(renderer)) {
    const canvas = renderer.domElement;
    currentLevel.onRenderTargetUpdate({ width: canvas.clientWidth, height: canvas.clientHeight });
  }

  requestAnimationFrame(animate);

  currentLevel.render();

  accum += clock.getDelta();

  if (accum > FRAME_DELTA) {
    currentLevel.animate(FRAME_DELTA, clock.getElapsedTime());
  }
}

function getAdaptiveAspectRatio(): string {
  const width = window.screen.availWidth;
  const height = window.screen.availHeight;
  if (width > height) {
    return (width / height).toString();
  }

  return (height / width).toString();
}

function resizeRendererToDisplaySize(renderer: Renderer) {
  const canvas = renderer.domElement;
  const pixelRatio = window.devicePixelRatio;
  const width = canvas.clientWidth * pixelRatio | 0;
  const height = canvas.clientHeight * pixelRatio | 0;
  const needResize = canvas.width !== width || canvas.height !== height;
  if (needResize) {
    canvas.style.aspectRatio = getAdaptiveAspectRatio();
    renderer.setSize(width, height, false);
  }
  return needResize;
}

async function onLevelChange(newLevel: LevelInterface) {
  if (currentLevel) {
    await currentLevel.dispose();
  }
  currentLevel = newLevel;
  await currentLevel.load();
}

async function main() {
  if (WEBGL.isWebGLAvailable()) {
    await onLevelChange(new Intro({renderer}));
    animate();
  } else {
    const warning = WEBGL.getWebGLErrorMessage();
    const container = document.getElementById('container');
    if (!container) {
      throw `Can't find warning container. Warning: ${warning}`;
    }
    container.appendChild(warning);
  }
}

main();
