import Collection from "framework/collection";

export type EventCallback<T> = (data: T) => void;

class HandlerList {
  private handlers: Array<EventCallback<any>> = [];

  public add(eventCallback: EventCallback<any>) {
    this.handlers.push(eventCallback);
  }

  public handle(data: any) {
    this.handlers.map(handler => handler(data));
  }
}

/**
 * Event system for reactive components.
 */
class Events {
  private callbacks: Collection<HandlerList>;

  public constructor() {
    this.callbacks = new Collection<HandlerList>();
  }

  public dispatch<T>(eventName: string, data: T) {
    const handler = this.callbacks.get(eventName);
    if (handler !== null) {
      handler.handle(data);
    }
  }

  public register<T>(eventName: string, callback: EventCallback<T>) {
    // TODO: if another callback is registered, then what? wrap existing callback in loop?
    let handler = this.callbacks.get(eventName);
    if (handler === null) {
      handler = new HandlerList();
    }

    handler.add(callback);
    this.callbacks.add(eventName, handler);
  }

  public unregister(callbackName: string) {
    // TODO: implement
  }
}

export default Events
