class FileName {
  /**
   * Get file name extension.
   *
   * Throws for files without extension and for empty string.
   *
   * @param file
   */
  public static getFileExtension(file: string): string {
    const fileParts = file.split('.');
    if (fileParts.length < 2) {
      throw this.throwUnableToResolveExtension(file);
    }
    const extension = fileParts.pop();

    if (!extension) {
      throw this.throwUnableToResolveExtension(file);
    }

    return extension;
  }

  private static throwUnableToResolveExtension(file: string): never {
    throw `Unable to resolve extension for: ${file}`;
  }
}

export default FileName;
