import * as THREE from "three";
import {Mesh} from "three";

export interface ParallaxInstancerDesc {
  mesh: Mesh;
  scene: THREE.Scene;

  clonesCount: number
}

const ParallaxInstancer = {
  prepareInstances: ({mesh, scene, clonesCount}: ParallaxInstancerDesc) => {
    const instances = new Array<THREE.Object3D>;
    let instance;
    instances.push(mesh);
    scene.add(mesh);
    for (let i = 0; i < clonesCount; ++i) {
      instance = mesh.clone();
      instances.push(instance);
      scene.add(instance);
    }

    return instances;
  }
}

export default ParallaxInstancer;
