/**
 * Module with timing functions.
 */

export function pingPong(value: number, scale: number = 1): number {
  return scale - Math.abs((Math.abs(value) % (2 * scale)) - scale);
}

export function easeInOutCubic(x: number): number {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}
