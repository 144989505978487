import Collection from "framework/collection";
import LoaderRepository from "model/loaderRepository";
import Bucket from "framework/bucket";
import {tryAddNonDisposable} from "framework/disposableWrapper";
import Events from "framework/events";

interface AssetFileInterface {
  name: string;
  file: string;
}

export interface AssetRepositoryDesc {
  loaderRepository: LoaderRepository;
  events?: Events;
}

/**
 * This is a loader and container for assets.
 * Every asset can be retrieved by its name.
 *
 * There is the dispose function which should be called to release resources claimed by assets.
 * If you need to dispose only specific assets, instantiate separate AssetRepository.
 *
 * Optional events parameter can be used to listen for the following events:
 * - model-loaded-[MODEL_NAME]
 */
class AssetRepository<AssetType> {
  private assetsBucket: Bucket = new Bucket();
  private assets: Collection<AssetType> = new Collection<AssetType>();
  private loaderRepository: LoaderRepository;
  private events?: Events;

  constructor({loaderRepository, events}: AssetRepositoryDesc) {
    this.loaderRepository = loaderRepository;
    this.events = events;
  }

  /**
   * Load assets.
   *
   * @param files
   */
  async load(files: Array<AssetFileInterface>) {
    const loadPromises = files.map((fileDesc) => this.loadAsset(fileDesc))

    return Promise.all(loadPromises);
  }

  /**
   * Remove all assets from the RAM and the GPU memory as well.
   */
  dispose() {
    this.assetsBucket.dispose();
    this.assets.clear();
  }

  /**
   * Retrieve asset by name.
   *
   * @param assetName
   */
  get(assetName: string) {
    const asset = this.assets.get(assetName);
    if (asset) {
      return asset;
    }
    throw `Asset ${assetName} was not loaded.`;
  }

  private async loadAsset({name, file}: AssetFileInterface) {
    const loader = this.loaderRepository.getLoaderForFile(file);
    if (!loader) {
      throw `Loader for file ${file} does not exist`;
    }
    //TODO: add logger/event handler to indicate whether model was loaded or an error occurred

    return loader.loadAsync(file)
      .then((asset: AssetType) => {
        tryAddNonDisposable(asset, this.assetsBucket);
        this.assets.add(name, asset);

        this.events?.dispatch(`model-loaded-${name}`, asset)
      })
  }
}

export default AssetRepository;
