import * as _ from "lodash";

type Keyword = string;
type Entry = string;
export type KeywordSetItem = Array<Entry>;
export type KeywordSetItems = { [key: Keyword]: KeywordSetItem };

type InvertedSetItems = { [key: Entry]: Keyword };

function invertSet(keywordSet: KeywordSetItems): InvertedSetItems {
  const result: InvertedSetItems = {};

  _.forEach(keywordSet, (item, keyword) => {
    _.forEach(item, (entry) => {
      result[entry] = keyword;
    })
  });

  return result;
}

const errorCouldNotFind = (itemName: string): string => {
  return `Could not get ${itemName}`;
};

/**
 * Use this as inverse lookup table with multiple entries (keywords).
 *
 * Example: search for the file loader based on the provided file types.
 */
class KeywordSet {
  private readonly items: KeywordSetItems;
  private readonly invertedItems: InvertedSetItems;

  constructor(items: KeywordSetItems) {
    this.items = items;
    this.invertedItems = invertSet(this.items);
  }

  /**
   * Get mapped Keyword for Entry. Returns undefined when Entry was not found.
   *
   * @param entry {Entry}
   * @return {Keyword | undefined}
   */
  public get(entry: Entry): Keyword | undefined {
    return this.invertedItems[entry];
  }

  /**
   * Throwable version of @see KeywordSet::get
   *
   * @param entry {Entry}
   * @return {Keyword}
   */
  public tryGet(entry: Entry): Keyword {
    const item = this.get(entry);
    if (item === undefined) {
      throw errorCouldNotFind(entry);
    }

    return item;
  }

  /**
   * Get entry by Keyword.
   * @param keyword {Keyword}
   */
  public getByKeyword(keyword: Keyword) {
    return this.items[keyword];
  }

  /**
   * Throwable version of @see KeywordSet::getByKeyword.
   * @param keyword {Keyword}
   */
  public tryGetByKeyword(keyword: Keyword) {
    const item = this.items[keyword];
    if (item === undefined) {
      throw errorCouldNotFind(keyword);
    }

    return item;
  }
}

export default KeywordSet;
