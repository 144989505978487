import {DisposableInterface} from "framework/interfaces";
import Bucket from "framework/bucket";

/**
 * Factory for instantiating objects with disposables method.
 * Usually this is suitable for all ThreeJS entities that allocate GPU memory.
 */
class DisposableWrapper implements DisposableInterface {
  object: any;

  public constructor(object: any) {
    if ('dispose' in object) {
      this.object = object;
    } else {
      if ('type' in object) {
        throw `object of type ${object.type} has no 'dispose' method`;
      }
      throw "object has no 'dispose' method";
    }
  }

  public dispose() {
    this.object.dispose();
  }
}

export function addNonDisposable(object: any, bucket: Bucket) {
  bucket.add(new DisposableWrapper(object))
}

export function tryAddNonDisposable(object: any, bucket: Bucket) {
  try {
    bucket.add(new DisposableWrapper(object))
  } catch (e) {
    // noop
  }
}
