import {Loader} from "three";
import Collection from "framework/collection";
import KeywordSet from "framework/keywordSet";
import FileName from "framework/fileName";

export type LoaderCollection = Collection<Loader>;

export interface LoaderRepositoryDesc {
  loaders: LoaderCollection;
  loaderExtensionMap: KeywordSet;
}

/**
 * Generic loader that resolves appropriate file loader from the file extension.
 * Multiple file extensions can be assigned to a loader.
 */
class LoaderRepository {
  private loaders: LoaderCollection;
  private loaderExtensionMap: KeywordSet;

  constructor({loaders, loaderExtensionMap}: LoaderRepositoryDesc) {
    this.loaders = loaders;
    this.loaderExtensionMap = loaderExtensionMap;
  }

  /**
   * @param file string
   * @throws Error
   */
  getLoaderForFile(file: string) {
    const extension = FileName.getFileExtension(file);

    return this.getLoaderForExtension(extension);
  }

  /**
   * @param extension string
   * @throws Error
   */
  private getLoaderForExtension(extension: string) {
    const loaderName = this.loaderExtensionMap.get(extension);
    if (loaderName) {
      return this.loaders.get(loaderName);
    }

    throw `Loader for extension '${extension}' does not exists`;
  }
}

export default LoaderRepository;
