import * as THREE from "three";
import {Mesh, Object3D} from "three";
import _ from "lodash";

export interface ParallaxAnimatorDesc {
  instances: Array<Object3D>;
  speed: number;
  boundingBox: THREE.Box3;
}

class ParallaxAnimator {
  private instances: Array<Object3D> = [];
  private margin: number = 0;
  private readonly boundaryNear: number;
  private readonly moveOffset: number;
  private readonly speed: number;
  private readonly boundaryFar: number;
  // Add some randomness to placing objects
  private readonly horizontalDisplacement: number = 0;

  private animateFunction: (instance: Object3D, delta: number) => void;

  constructor({instances, speed, boundingBox}: ParallaxAnimatorDesc) {
    this.instances = instances;
    this.speed = speed;

    this.moveOffset = boundingBox.max.x - boundingBox.min.x + this.margin;

    this.boundaryNear = -this.moveOffset;
    this.boundaryFar = (this.instances.length - 1) * this.moveOffset;
    this.animateFunction = this.animatePositive;
    if (speed < 0) {
      this.boundaryNear = -this.boundaryNear;
      this.boundaryFar = -this.boundaryFar;
      this.animateFunction = this.animateNegative;
    }

    this.prepareInstances();
  }

  animate(delta: number) {
    // TODO: set parent node for instances?
    _.each(this.instances, (instance) => {
      this.animateFunction(instance, delta);
      this.checkLod(instance);
    });
  }

  private animatePositive(instance: Object3D, delta: number) {
    instance.position.x -= this.speed * delta;
    if (instance.position.x <= this.boundaryNear) {
      instance.position.x = this.boundaryFar;
      instance.position.z = Math.random() * this.horizontalDisplacement;
    }
  }

  private animateNegative(instance: Object3D, delta: number) {
    instance.position.x -= this.speed * delta;
    if (instance.position.x >= this.boundaryNear) {
      instance.position.x = this.boundaryFar;
      instance.position.z = Math.random() * this.horizontalDisplacement;
    }
  }

  private normalize(x: number, min: number, max: number) {
    return (x - min) / (max - min);
  }

  private checkLod(instance: Object3D) {
    const scaleDistance = Math.abs(this.boundaryFar) - 777;
    const absDistance = Math.abs(instance.position.x);
    if (absDistance > scaleDistance) {
      const scale = this.normalize(absDistance, Math.abs(this.boundaryFar), scaleDistance);
      instance.scale.set(1, scale, 1);
    } else {
      instance.scale.set(1, 1, 1);
    }
  }

  private prepareInstances() {
    let iteration = 0;
    _.each(this.instances, (instance) => {
      if (this.speed < 0) {
        instance.position.x = iteration++ * -this.moveOffset;
      } else {
        instance.position.x = iteration++ * this.moveOffset;
      }
      instance.position.z = Math.random() * this.horizontalDisplacement;
    })
  }
}

export default ParallaxAnimator;
