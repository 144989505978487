import * as THREE from 'three';
import {Color, DirectionalLight, Loader, Mesh, Object3D, Renderer, Vector3} from 'three';
import "regenerator-runtime";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {LevelDescInterface, LevelInterface, ScreenSizeInterface} from "level/level";
import AssetRepository from "model/assetRepository";
import Collection from "framework/collection";
import LoaderRepository, {LoaderCollection} from "model/loaderRepository";
import KeywordSet from "framework/keywordSet";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import Theme from "level/pyramid/theme";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import Events from "framework/events";
import {UnrealBloomPass} from "three/examples/jsm/postprocessing/UnrealBloomPass";
import Parallax from "harmony/parallax";
import {FlyControls} from "three/examples/jsm/controls/FlyControls";
import {easeInOutCubic, pingPong} from "harmony/timingFx";
import {getLoadingManager} from "framework/loadingManager";

const ENTIRE_SCENE = 0, BLOOM_SCENE = 1;
const bloomLayer = new THREE.Layers();
bloomLayer.set(BLOOM_SCENE);

const theme = new Theme();
const themeColors = theme.getTheme("cyber2");
let scene = new THREE.Scene();

// TODO: window violates separation from HTML
const camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  5,
  2000
);
camera.position.x = 40;
camera.position.y = 45;
camera.position.z = 60;
camera.lookAt(camera.position.x + 100, camera.position.y, camera.position.z);
scene.add(camera);

let activeCamera = camera;

async function loadModels() {
  await meshRepository.load([
    {name: 'fuji', file: 'models/fuji.glb'},
    {name: 'fake-city', file: 'models/fake-city.glb'},
    {name: 'vapor-sun', file: 'models/vapor-sun.glb'},
  ]);
}

const modelRepoEvents = new Events;
const meshLoaders: LoaderCollection = new Collection<Loader>();

const loadingManager = getLoadingManager();
const gltfLoader = new GLTFLoader(loadingManager);
const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setWorkerLimit(2);

// TODO: this loader is hacky, it is copied in package.json
dracoLoader.setDecoderPath("js/libs/draco/");
gltfLoader.setDRACOLoader(dracoLoader);

meshLoaders.add("gltf", gltfLoader);
const meshLoaderExtensionMap = new KeywordSet({
  gltf: ["glb", "gltf"]
});
const meshLoaderRepository = new LoaderRepository({
  loaders: meshLoaders,
  loaderExtensionMap: meshLoaderExtensionMap
});
const meshRepository = new AssetRepository<Mesh>({
  loaderRepository: meshLoaderRepository,
  events: modelRepoEvents
});

function createFuji() {
  modelRepoEvents.register(`model-loaded-fuji`, (gltf) => {
    const fujiLeft = gltf.scene;
    fujiLeft.traverse(function (child: Object3D) {
      if (child instanceof Mesh) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });

    const zSpacing = 512;

    fujiLeft.position.x = camera.position.x + 1500;
    fujiLeft.position.y = 80;
    fujiLeft.position.z = camera.position.z - zSpacing;
    fujiLeft.rotateY(-130);
    fujiLeft.scale.x = fujiLeft.scale.y = fujiLeft.scale.z = 30;

    const fujiRight = fujiLeft.clone();
    fujiRight.rotateY(-10);
    fujiRight.position.x -= 120;
    fujiRight.position.z = camera.position.z + zSpacing;

    scene.add(fujiRight);
    scene.add(fujiLeft);
  });
}

function createVaporSun() {
  modelRepoEvents.register(`model-loaded-vapor-sun`, (gltf) => {
    const model = gltf.scene;
    model.traverse(function (child: Object3D) {
      if (child instanceof Mesh) {
        child.castShadow = false;
        child.receiveShadow = false;
        child.material.fog = false;
      }
    });
    model.position.x = camera.position.x + 3500;
    model.position.y = 400;
    model.position.z = camera.position.z;
    model.scale.x = model.scale.y = model.scale.z = 380;
    scene.add(model);
    createLighting(model);
  });
}

let cityParallax: Parallax;

function createFakeCity() {
  modelRepoEvents.register(`model-loaded-fake-city`, (gltf) => {
    const mesh = gltf.scene;
    mesh.traverse(function (child: Object3D) {
      if (child instanceof Mesh) {
        let material = child.material;

        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });

    cityParallax = new Parallax({mesh, scene, speed: 1024});
  });
}


function setupSunShadow(sunLight: DirectionalLight) {
  sunLight.castShadow = true;

  // Set up shadow properties for the light
  const sunCameraSize = 200;
  const shadowMapSize = 512;
  sunLight.shadow.mapSize.width = shadowMapSize;
  sunLight.shadow.mapSize.height = shadowMapSize;
  sunLight.shadow.camera.near = 1;
  sunLight.shadow.camera.far = 1000;
  sunLight.shadow.camera.right = sunCameraSize;
  sunLight.shadow.camera.left = -sunCameraSize;
  sunLight.shadow.camera.top = sunCameraSize;
  sunLight.shadow.camera.bottom = -sunCameraSize;
  sunLight.shadow.radius = 2;
  sunLight.shadow.blurSamples = 4;
  sunLight.shadow.normalBias = 0.5;
}

let sunLight: DirectionalLight;

function createLighting(vaporSun: Object3D) {
  const fog = new THREE.FogExp2(new Color(themeColors.detail).getHex(), 0.0005);
  scene.fog = fog;

  sunLight = new DirectionalLight("#ffffff", 1);
  const hemiLight = new THREE.HemisphereLight(themeColors.skyColor, themeColors.groundColor, 0.6);

  hemiLight.position.set(0, 200, 0);

  sunLight.color = themeColors.detail;
  // sunLight.position.copy(vaporSun.position);
  sunLight.position.set(10, 3, 0);
  sunLight.position.multiplyScalar(100);
  sunLight.lookAt(camera.position);

  scene.add(sunLight);
  scene.add(hemiLight);

  // const helper = new THREE.DirectionalLightHelper(sunLight, 5);
  // scene.add(helper);

  // const cameraHelper = new THREE.CameraHelper(sunLight.shadow.camera);
  // scene.add(cameraHelper);

  setupSunShadow(sunLight);
}

function shake(position: THREE.Vector3, elapsedTime: number, offset?: Vector3) {
  const elapsedPeriod = (Math.sin(elapsedTime) + 1) / 2;
  const elapsedPeriodY = (Math.cos(elapsedTime) + 1) / 2;

  position.y = pingPong(easeInOutCubic(elapsedPeriodY) * 10, 10);
  position.z = pingPong(easeInOutCubic(elapsedPeriod) * 10, 10);

  if (offset) {
    position.add(offset);
  }
}

function createCubeMap() {
  const path = 'models/cubemap/Vapor-1/';
  const format = '.png';
  const urls = [
    path + 'px' + format, path + 'nx' + format,
    path + 'py' + format, path + 'ny' + format,
    path + 'pz' + format, path + 'nz' + format
  ];

  const reflectionCube = new THREE.CubeTextureLoader().load(urls);
  const refractionCube = new THREE.CubeTextureLoader().load(urls);
  refractionCube.mapping = THREE.CubeRefractionMapping;

  reflectionCube.needsUpdate = true;

  scene.background = reflectionCube;
  scene.environment = refractionCube;
}

function setupScene() {
  createCubeMap();
  createVaporSun();
  createFuji();
  createFakeCity();
}

let controls: FlyControls;
const cameraOffset = new Vector3(0, 50, 60);

function setupPointerLockControls(renderer: Renderer) {
  controls = new FlyControls(camera, renderer.domElement);

  controls.domElement = renderer.domElement;
  controls.rollSpeed = Math.PI / 24;
  controls.movementSpeed = 0;
}

class Intro implements LevelInterface {
  private levelDesc: LevelDescInterface;
  private composer: EffectComposer;

  public constructor(levelDesc: LevelDescInterface) {
    this.levelDesc = levelDesc;
    this.composer = new EffectComposer(levelDesc.renderer);
  }

  updateCubeCamera(renderer: THREE.WebGLRenderer) {
  }

  async load() {
    setupPointerLockControls(this.levelDesc.renderer);
    setupScene();
    await loadModels();
    this.setupComposerPasses();
  }

  async dispose() {
    meshRepository.dispose();
  }

  animate(delta: number, elapsedTime: number) {
    shake(camera.position, elapsedTime, cameraOffset);
    controls.update(delta);
    cityParallax?.animate(delta);
  }

  slowAnimate(delta: number) {
    // skyMotion.update();
  }

  onRenderTargetUpdate({width, height}: ScreenSizeInterface) {
    activeCamera.aspect = width / height;
    activeCamera.updateProjectionMatrix();
  }

  private setupComposerPasses() {
    const renderPass = new RenderPass(scene, activeCamera);
    const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
    bloomPass.threshold = 0.21;
    bloomPass.strength = 1.1;
    bloomPass.radius = 0.09;

    this.composer.addPass(renderPass);
    this.composer.addPass(bloomPass);
  }

  render() {
    this.composer.render();
  }
}

export default Intro;
