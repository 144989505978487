/**
 * Collection is a container for items with random access.
 */
class Collection<T> {
  private readonly items: Map<string, T>;

  constructor() {
    this.items = new Map();
  }

  public add(name: string, item: T) {
    this.items.set(name, item);
  }

  public get(name: string): T | null {
    const item = this.items.get(name);
    if (item === undefined) {
      return null;
    }

    return item;
  }

  public getAll(): Map<string, T> {
    return this.items;
  }

  public clear(): void {
    this.items.clear();
  }
}

export default Collection;
