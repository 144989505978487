import * as THREE from "three";

export interface ThemeDesc {
  main: THREE.Color;
  detail: THREE.Color;
  skyColor: THREE.Color;
  groundColor: THREE.Color;
}

const themes = {
  a: {
    main: new THREE.Color("#b9fbc0"),
    detail: new THREE.Color("#f1c0e8"),
    skyColor: new THREE.Color("#a3c4f3"),
    groundColor: new THREE.Color("#fbf8cc"),
  },
  b: {
    main: new THREE.Color("#ffffff"),
    detail: new THREE.Color("#fca311"),
    skyColor: new THREE.Color("#e5e5e5"),
    groundColor: new THREE.Color("#014837"),
  },
  c: {
    main: new THREE.Color("#f1faee"),
    detail: new THREE.Color("#e63946"),
    skyColor: new THREE.Color("#a8dadc"),
    groundColor: new THREE.Color("#1d3557"),
  },
  d: {
    main: new THREE.Color("#a319ff"),
    detail: new THREE.Color("#ff2086"),
    skyColor: new THREE.Color("#70e0ff"),
    groundColor: new THREE.Color("#29008a"),
  },
  e: {
    main: new THREE.Color("#ff00c1"),
    detail: new THREE.Color("#00fff9"),
    skyColor: new THREE.Color("#9600ff"),
    groundColor: new THREE.Color("#4900ff"),
  },
  f: {
    main: new THREE.Color("#95BF8F"),
    detail: new THREE.Color("#EDA2F2"),
    skyColor: new THREE.Color("#006C67"),
    groundColor: new THREE.Color("#48BEFF"),
  },
  g: {
    main: new THREE.Color("#797d62"),
    detail: new THREE.Color("#ffcb69"),
    skyColor: new THREE.Color("#d9ae94"),
    groundColor: new THREE.Color("#997b66"),
  },
  //#c700c4
  // #93b4ea
  // #6600a4
  // #8800ab
  // #3b0047
  cyberPunk: {
    main: new THREE.Color("#c700c4"),
    detail: new THREE.Color("#93b4ea"),
    skyColor: new THREE.Color("#6600a4"),
    groundColor: new THREE.Color("#3b0047"),
  },
  cyber2: {
    main: new THREE.Color("#4dc8fa"),
    detail: new THREE.Color("#df00e1"),
    skyColor: new THREE.Color("#6c37e4"),
    groundColor: new THREE.Color("#330957"),
  },
  cyber2inv: {
    detail: new THREE.Color("#df00e1"),
    groundColor: new THREE.Color("#4dc8fa"),
    skyColor: new THREE.Color("#6c37e4"),
    main: new THREE.Color("#330957"),
  },
  cyberOrange: {
    detail: new THREE.Color("#df00e1"),
    groundColor: new THREE.Color("#D3C4E3"),
    skyColor: new THREE.Color("#BD4700"),
    main: new THREE.Color("#372554"),
  }
};

type ThemeId = keyof typeof themes;

class Theme {
  getRandomTheme() {
    const themeKeys = Object.keys(themes);
    const currentDate = new Date();
    const randomizedThemeKey = currentDate.getMinutes() % themeKeys.length;
    const randomTheme: keyof typeof themes = themeKeys[randomizedThemeKey] as keyof typeof themes;

    return themes[randomTheme];
  }

  getTheme(id: ThemeId): ThemeDesc {
    return themes[id];
  }
}

export default Theme;
