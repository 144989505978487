import * as THREE from "three";
import {Mesh} from "three";
import ParallaxAnimator from "harmony/parallaxAnimator";
import ParallaxInstancer from "harmony/parallaxInstancer";

export interface ParallaxDesc {
  mesh: Mesh;
  scene: THREE.Scene;
  speed: number;
}

class Parallax {
  private clonesCount: number = 5;
  private readonly parallaxAnimator: ParallaxAnimator;

  constructor({mesh, scene, speed}: ParallaxDesc) {
    // TODO: compute real bounding box from all children
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(mesh);

    const instances = ParallaxInstancer.prepareInstances({mesh, scene, clonesCount: this.clonesCount});
    this.parallaxAnimator = new ParallaxAnimator({
      instances,
      speed,
      boundingBox
    });
  }

  animate(delta: number) {
    this.parallaxAnimator.animate(delta);
  }
}

export default Parallax;
