import * as THREE from "three";

/**
 * Default loading manager.
 *
 * fadeoutDuration determines how long does it take to hide the loader overlay after resources have been loaded.
 */
const configureLoadingManager = () => {
  const fadeoutDelay = 50;
  const fadeoutDuration = 1000;
  const hideTimeout = fadeoutDuration + fadeoutDelay;
  // TODO: pass element as dependency
  const loaderElement = document.getElementById("loader");
  const loaderProgressElement = document.getElementById("loader-progress");
  if (!loaderElement || !loaderProgressElement) {
    console.error('Unable to find loader HTML elements!');
    return null;
  }

  const manager = new THREE.LoadingManager();
  manager.onStart = function (url, itemsLoaded, itemsTotal) {
    console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
    loaderProgressElement.innerText = "0%";
    loaderElement.style.display = "flex";

    loaderElement.style.transitionProperty = 'opacity';
    loaderElement.style.transitionTimingFunction = 'easeInOut';
    loaderElement.style.transitionDuration = `${fadeoutDuration}ms`;
    loaderElement.style.transitionDelay = `${fadeoutDelay}ms`;
    loaderElement.style.opacity = '1';
  };

  manager.onLoad = function () {
    console.log('Loading complete!');
    loaderElement.style.opacity = '0';
    setTimeout(() => {
      loaderElement.style.display = "none";
    }, hideTimeout);
  };

  manager.onProgress = function (url, itemsLoaded, itemsTotal) {
    const content = 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.';
    loaderProgressElement.innerText = content;
    console.log(content);
  };

  manager.onError = function (url) {
    console.log('There was an error loading ' + url);
  };

  return manager;
}

export const getLoadingManager = () => {
  let loadingManager;
  if (!loadingManager) {
    loadingManager = configureLoadingManager();
  }

  return loadingManager;
}
